import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLink from "../components/socialLink"

const BioPage = ({data, location}) => {
  const author = data.site.siteMetadata?.author
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="biography" />
      <div className="main-heading">
        <h1>biography</h1>
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/icon.png"
          width={100}
          height={100}
          quality={95}
          alt="Profile picture"
        />
        <h4 className="bio-author">{author.name}</h4>
        <p>{author?.summary || null}</p>
        <p className="bio-summary">{author?.summary2 || null}</p>
        <a className="bio-link" href="http://about.mayu-watanabe.com/" target="_blank" rel="noreferrer" >More about me</a>
        <SocialLink />
      </div>
    </Layout>
  )
}

export default BioPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          name
          summary
          summary2
        }
        social {
          twitter
        }
      }
    }
  }
`
